import styled from "styled-components";

export const BuilderUI = styled.ul`
  .builder-sidebar_search {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
  }
  .formarea {
    background: ${(props) =>
      props.isDarkMode ? "#6a6a6a73 !important;" : "#e6e6e673 !important"};
  }
  .form-builder-panel {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
    margin: 2px;
  }
  .btn {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
  }
  .formcomponent {
    width: 100%;
    color: white;
  }
  .form-control {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
    font-size: 14px;
    padding: 10px 0.75rem;
    min-height: 38px !important;
  }
  .ui.selection.dropdown {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
    font-size: 14px;
    padding: 5px 0.75rem !important;
    min-height: 38px !important;
  }
  .card {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
  }
  .choices__list--dropdown .choices__list {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
  }
  .choices__list--dropdown {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    background: ${(props) => (props.isDarkMode ? "black" : "white")};
  }
  .ui.selection.dropdown {
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
    border: 1px solid #dbdbdb !important;
    border-radius: 5px !important;
  }
  .is-disabled .ui.selection.dropdown {
    background: ${(props) =>
      props.isDarkMode ? "black !important" : "#e9ecef !important"};
  }
  .ui.selection.dropdown {
    background: ${(props) =>
      props.isDarkMode ? "black !important" : "white !important"};
  }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background: blue;
  }
  .formio-component .ui div {
    margin: 2px !important;
  }
  .formio-component .nav-tabs .nav-link.active {
    margin-top: 12px !important;
  }
  .formio-dialog.formio-dialog-theme-default .formio-dialog-content {
    background: ${(props) =>
      props.isDarkMode ? "black !important" : "white !important"};
    color: ${(props) => (props.isDarkMode ? "white" : "black")};
  }

  .form-builder-group-header .btn {
    padding: 0px;
    font-size: 15px;
    text-align: left;
  }
  .formio-choices.form-group {
    border: 1px solid #dbdbdb !important;
    border-radius: 5px;
  }
  .required:after {
    content: "";
    color: red;
  }
  .formio-component-datetime {
    min-width: 200px;
  }
  .formio-component-datetime
    .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .formio-component-datetime .input-group-text {
    padding: 11px 9px;
    font-size: 15px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .formio-component-datetime .input-group {
    border-bottom: none !important;
    padding: 0px;
  }
  .formio-component-datetime
    .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .formio-component-emailContainer tr {
    height: 55px;
  }
  .formio-component-DatePickerComponent .MuiTextField-root {
    width: 100%;
    height: 48px;
    margin-top: 3%;
  }
  [ref="buttonMessageContainer"].has-error {
    display: none;
  }

  .form-group {
    position: relative;
  }

  td .formio-component-select .ui.selection.dropdown {
    padding: 0px 0.75rem !important;
  }
  .MuiStack-root {
    padding: 0px;
    overflow: hidden;
  }
  .formio-component-DatePickerComponent .MuiTextField-root {
    height: auto;
  }
  .choices[data-type*="select-one"] .choices__input {
    background: ${(props) =>
      props.isDarkMode ? "black !important" : "white !important"};
  }
`;
