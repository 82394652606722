import { Paper, Typography } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Form, Formio } from "react-formio";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import LogoMonogram from "../../components/Loader/LogoMonogram";
import {
  SetCaseVariable,
  casesFormActivitySubmit,
  queryExecute,
  uploadFiles,
  webentryGetCaseDetails,
  webentryGetFormContent,
} from "../../network/networks";
import Colors from "../../values/colors";
import { formIoData } from "../Cases/BuilderConst";
import AddressDialog from "../Cases/FomDialogs/AddressDialog";
import BuyerGroupDialog from "../Cases/FomDialogs/BuyerGroupDialog";
import CenterDialog from "../Cases/FomDialogs/CenterDialog";
import CostCenterDialog from "../Cases/FomDialogs/CostCenterDialog";
import InternalOrderDialog from "../Cases/FomDialogs/InternalOrderDialog";
import ProviderDialog from "../Cases/FomDialogs/ProviderDialog";
import PuchaseOrganizationDialog from "../Cases/FomDialogs/PurchaseOrganizationDialog";
import {
  updateAnyFieldInNestedArray,
  updateNestedArray,
  updateSQLDataInNestedArray,
} from "../Cases/VariableMapping";

import { useSelector } from "react-redux";
import Images from "../../assets";
import { callRegisterWorkflow } from "../../network/networks";
import MultiStepFormComponent from "../Designer/designerForms/FormBuilder/Custom/MultistepForm";
import SelectComponent from "../Designer/designerForms/FormBuilder/Custom/SelectComponent";
import DatePickerComponent from "../Designer/designerForms/FormBuilder/Custom/DatePicker";
import GeoLocationComponent from "../Designer/designerForms/FormBuilder/Custom/GeoLocation";
import QRScannerComponent from "../Designer/designerForms/FormBuilder/Custom/QRScanner";
import { useForm } from "react-hook-form";
import { getElement } from "../Cases/AiraJSFunctions";

import "../Cases/elisStyle.css";
import "../Cases/styles.css";
import { BuilderUI } from "../Cases/FormBuilderUI";

//   .builder-sidebar_search {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     background: ${(props) => (props.isDarkMode ? "black" : "white")};
//   }
//   .form-builder-panel {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     background: ${(props) => (props.isDarkMode ? "black" : "white")};
//     margin: 2px;
//   }
//   .btn {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//   }
//   .formcomponent {
//     width: 100%;
//     color: white;
//   }
//   .form-control {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     background: ${(props) => (props.isDarkMode ? "black" : "white")};
//     font-size: 12px;
//   }
//   .card {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     background: ${(props) => (props.isDarkMode ? "black" : "white")};
//   }
//   .choices__list--dropdown .choices__list {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     background: ${(props) => (props.isDarkMode ? "black" : "white")};
//   }
//   .choices__list--dropdown {
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     background: ${(props) => (props.isDarkMode ? "black" : "white")};
//   }
//   .ui.selection.dropdown {
//     background: ${(props) =>
//       props.isDarkMode ? "black !important" : "white !important"};
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//     border: 1px solid #dbdbdb !important;
//     border-radius: 5px !important;
//   }
//   .choices__list--dropdown .choices__item--selectable.is-highlighted {
//     background: blue;
//   }
//   .formio-component .ui div {
//     margin: 2px !important;
//   }
//   .formio-component .nav-tabs .nav-link.active {
//     margin-top: 12px !important;
//   }
//   .formio-dialog.formio-dialog-theme-default .formio-dialog-content {
//     background: ${(props) =>
//       props.isDarkMode ? "black !important" : "white !important"};
//     color: ${(props) => (props.isDarkMode ? "white" : "black")};
//   }
//   .form-builder-group-header .btn {
//     padding: 0px;
//     font-size: 15px;
//     text-align: left;
//   }
//   .formio-choices.form-group {
//     border: 1px solid #dbdbdb !important;
//     border-radius: 5px;
//   }
//   .required:after {
//     content: "";
//     color: red;
//   }
// `;
const FormRendering = (props) => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const { targetFormToRender, caseItem, onFormSubmit } = props;
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [formContent, setFormContent] = useState(formIoData);
  const [openCostCenterDialog, setOpenCostCenterDialog] = useState(false);
  const [openBuyerGoupDialog, setOpenBuyerGoupDialog] = useState(false);
  const [openPuchaseOrgDialog, setopenPuchaseOrgDialog] = useState(false);
  const [internalOrderDialog, setInternalOrderDialog] = useState(false);
  const [submitCounter, setSubmitCounter] = useState(0);
  const [centerDialog, setCenterDialog] = useState(false);
  const [addressDialog, setAddressDialog] = useState(false);
  const [providersDialog, setProvidersDialog] = useState(false);
  const [showDialogLoader, setShowDialogLoader] = useState(false);
  const [params, setParams] = useState({});
  const [fileData, setFileData] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [contentData, setContentData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageContent, setPageContent] = useState({});
  const [_form_id, set_form_id] = useState("");
  const [targetForm, setTargetForm] = useState({});
  const [formJSON, setFormJSON] = useState("");
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);
  const [showJSError, setShowJSError] = useState(false);
  const [JSErrorMsg, setJSErrorMsg] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    Formio.Components.setComponent(
      "MultiStepFormComponent",
      MultiStepFormComponent
    );
    Formio.Components.setComponent("SelectComponent", SelectComponent);
    Formio.Components.setComponent("DatePickerComponent", DatePickerComponent);
    Formio.Components.setComponent(
      "GeoLocationComponent",
      GeoLocationComponent
    );
    Formio.Components.setComponent("QRScannerComponent", QRScannerComponent);
  }, [1]);
  var res = [];
  const isDarkMode = useSelector((state) => state.theme.isDarkMode, _.isEqual);
  const location = useLocation();
  const navigate = useNavigate();

  const getAxiosInstance = (token) => {
    let formAxiosInstance = axios.create();
    formAxiosInstance.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    return formAxiosInstance;
  };
  useEffect(() => {
    set_form_id(targetFormToRender?.form_id);
    setTargetForm(targetFormToRender);
  }, [targetFormToRender]);
  // const _form_id = targetFormToRender?.form_id;
  const executeSQLAPI = useCallback(
    (content, data, _token, _form_id) => {
      let _content = [...content];
      let _data = [...data];
      _data.map(async (item, i) => {
        if (item.sql !== undefined && item.sql !== "") {
          const regex1 = /(\$\$.*?\$\$)/gi;
          let matched_arr = item.sql.match(regex1);
          let raw = {
            form_id: `${_form_id}`,
            field_id: item.key,
          };
          if (!_.isNull(matched_arr) && !_.isUndefined(matched_arr)) {
            let _new_obj = {};
            matched_arr.map((v) => {
              // let _str = v.replace(/^\$+|\$+$/g, "");
              // console.log("Formio.submission", Formio);
              // console.log(
              //   "data[grids4][pickupDate]",
              //   getElementValue(`data[grids4][${_str}]`)
              // );
              //  _new_obj[_str] = _.has(variables, _str) ? variables[_str] : "";
            });

            raw = { ...raw, ..._new_obj };
          }
          let _axiosInstance = getAxiosInstance(_token);
          await _axiosInstance
            .post(`${queryExecute}`, raw, {
              headers: {
                Authorization: `Bearer ${_token}`,
              },
            })
            .then(async (res) => {
              if (item.type === "select" || item.type === "SelectComponent") {
                let temp = [];
                res.data.map((_v, _i) => {
                  let _keys = Object.keys(_v);
                  let label = _keys.length > 1 ? _v[_keys[1]] : _v[_keys[0]];
                  let value = _v[_keys[0]];
                  temp.push({ label: label, value: value });
                });
                if (temp.length === res.data.length) {
                  _content = await updateSQLDataInNestedArray(
                    [..._content],
                    "key",
                    item.key,
                    { values: temp }
                  );
                  formContent.components = _content;
                  setFormContent({
                    display: "form",
                    components: _content,
                  });
                }
              } else if (item.type === "textfield" || item.type === "email") {
                let _dataobj = res.data[0];
                let keys = Object.keys(res.data[0]);
                _content = await updateNestedArray(
                  [..._content],
                  "key",
                  item.key,
                  _dataobj[keys[0]]
                );
                setValue(item.key, _dataobj[keys[0]]);
                formContent.components = _content;
                setFormContent({
                  display: "form",
                  components: _content,
                });
              } else {
              }
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
        if (i === data.length - 1) {
          console.log("i am in true condition");
        }
      });
    },
    [pageContent._id]
  );
  const getSqlData = useCallback(
    async (arr, key, mainContent) => {
      let _arr = [...arr];
      let flag;
      let updatedContent = [...mainContent];
      await _arr.map(async (_obj) => {
        let obj = { ..._obj };
        if (Object.keys(obj).includes(key)) flag = true;
        if (flag) {
          if (obj?.connection && obj?.sql && obj?.sql !== "") {
            res.push(obj);
            flag = false;
          }
        }
        Object.entries(obj).forEach((ele) => {
          if (Array.isArray(ele[1])) {
            getSqlData(ele[1], key, updatedContent);
          }
        });
      });
      return res;
    },
    [res]
  );

  const executeSQLQuery = useCallback(
    async (content, formResData) => {
      let _content = content;
      console.log("pageContent in executeSQLQuery", formResData);
      let _form_id = formResData._id;
      let _token = formResData.token.token;
      if (_.isNil(_form_id)) {
        return;
      }
      let filtered_data = getSqlData(_content, "sql", _content);
      filtered_data.then(async (data) => {
        if (!_.isEmpty(data)) {
          executeSQLAPI(_content, data, _token, _form_id);
        }
      });
    },
    [executeSQLAPI, getSqlData, pageContent?._id]
  );
  const fetchCasesContent = useCallback((code) => {
    setIsDataFetching(true);
    let getFormAxiosInstance = axios.create();
    getFormAxiosInstance
      .get(`${webentryGetFormContent}${code}`)
      .then(async (res) => {
        // console.log("res.data", res.data);
        setPageContent(res.data);
        setFormContent({
          display: "form",
          components: JSON.parse(res.data.content).formJson,
        });
        // console.log("script on load", JSON.parse(res.data.content).script);
        setFormJSON(JSON.parse(res.data.content).script);
        executeSQLQuery(JSON.parse(res.data.content).formJson, res.data);
        setIsDataFetching(false);
      })
      .catch((err) => {
        setIsDataFetching(false);
        toast.error(err.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
  }, []);
  useEffect(() => {
    let param = { code: "" };
    for (let [key, value] of searchParams.entries()) {
      param[key] = value;
    }
    console.log("param", param);
    if (!_.isEmpty(param?.code)) {
      fetchCasesContent(param?.code);
    }
    setParams(param);
  }, [fetchCasesContent]);
  const executeFieldSQLQuery = async (
    content,
    variables,
    key_name,
    key_value
  ) => {
    let _content = content;
    let _formContent = formContent.components;
    _formContent = updateNestedArray(
      _formContent,
      "key",
      key_name,
      key_value,
      "defaultValue"
    );
    await _content.map(async (v, i) => {
      if (v.sql !== undefined && v.sql !== "") {
        const regex1 = /(\$\$.*?\$\$)/gi;
        let matched_arr = v.sql.match(regex1);
        let _form_id = targetFormToRender?.form_id;
        let raw = {
          form_id: _.isNil(_form_id) ? pageContent._id : `${_form_id}`,
          field_id: v.key,
        };

        _formContent = updateNestedArray(
          _formContent,
          "key",
          v.key,
          "",
          "defaultValue"
        );
        console.log("_formContent", _formContent);
        setFormContent({
          display: "form",
          components: _formContent,
        });

        if (!_.isNull(matched_arr)) {
          let _new_obj = {};
          matched_arr.map((v) => {
            let _str = v.replace(/^\$+|\$+$/g, "");
            _new_obj[_str] = _.has(variables, _str) ? variables[_str] : "";
          });
          raw = { ...raw, ..._new_obj };
        }

        let _axiosInstance = getAxiosInstance(pageContent.token.token);
        await _axiosInstance
          .post(`${queryExecute}`, raw)
          .then((res) => {
            if (v.type === "select" || v.type === "SelectComponent") {
              let temp = [];
              res.data.map((_v, _i) => {
                let _keys = Object.keys(_v);
                let label = _v[_keys[0]];
                let value = _keys.length > 1 ? _v[_keys[1]] : _v[_keys[0]];
                temp.push({ label: label, value: value });
              });
              _formContent = updateSQLDataInNestedArray(
                [..._formContent],
                "key",
                v.key,
                { values: temp }
              );
              formContent.components = _formContent;
              // setFormContent({
              //   display: "form",
              //   components: _formContent,
              // });
            } else {
              _formContent = updateNestedArray(
                _formContent,
                "key",
                v.key,
                JSON.stringify(res.data),
                "defaultValue"
              );
              console.log("_formContent", _formContent);
              formContent.components = _formContent;
              // setFormContent({
              //   display: "form",
              //   components: _formContent,
              // });
            }
          })
          .catch((err) => {
            console.log(err.response);
          });
      }
    });

    setTimeout(() => {
      executeFormJSonChange();
    }, 1000);
  };
  const onSubmissionChange = async (submission) => {
    if (
      !_.isUndefined(submission.changed) &&
      submission.changed?.component.key
    ) {
      setIsChanged((prevState) => !prevState);
      let _formContent = formContent.components;
      if (submission.changed?.component.type !== "button") {
        const regex1 = /(\$\$.*?\$\$)/gi;

        if (
          submission.changed?.instance?.parent?.type === "datagrid" &&
          submission.changed?.component.type !== "file"
        ) {
          let parent_key = submission.changed?.instance?.parent?.component?.key;
          let child_key = submission.changed?.component.key;
          let parent_ind = submission.changed?.instance?.rowIndex;

          _formContent = updateNestedArray(
            _formContent,
            "key",
            parent_key,
            submission.data[parent_key],
            "defaultValue"
          );
          _formContent = updateNestedArray(
            _formContent,
            "key",
            child_key,
            submission.data[parent_key][parent_ind][child_key],
            "defaultValue"
          );
          console.log("in second block", _formContent);
          setValue(
            parent_key,
            submission.changed?.instance?.parent?._data[parent_key]
          );
          formContent.components = _formContent;
        } else if (submission.changed?.component.type === "file") {
          console.log("submission?.data", submission?.data);
          let data = [];
          setIsFileUploading(true);

          if (submission.changed?.instance?.parent?.type !== "datagrid") {
            submission?.data[submission.changed?.component?.key].map((v, i) => {
              let dataToSubmit = {
                activity_id: `${pageContent.job_activity_id}`,
                job_id: `${pageContent.job_id}`,
                file: [v],
              };
              let _axiosInstance = getAxiosInstance(pageContent.token.token);
              _axiosInstance
                .post(uploadFiles, dataToSubmit)
                .then((res) => {
                  data.push({
                    file_id: res.data[i],
                    storage: v.storage,
                    name: v.name,
                    size: v.size,
                    type: v.type,
                    originalName: v.originalName,
                    // url: v.url,
                    hash: v.hash,
                  });
                  if (
                    data.length ===
                    submission.data[submission.changed.component.key].length
                  ) {
                    setFileData({ [submission.changed.component.key]: data });
                    setIsFileUploading(false);
                    Object.keys(contentData).map((v) => {
                      _formContent = updateNestedArray(
                        _formContent,
                        "key",
                        v,
                        contentData[v],
                        "defaultValue"
                      );
                    });

                    _formContent = updateNestedArray(
                      _formContent,
                      "key",
                      submission.changed?.component.key,
                      data,
                      "defaultValue"
                    );
                    setValue(submission.changed?.component.key, data);
                    setFormContent({
                      display: "form",
                      components: _formContent,
                    });

                    toast.success("File uploaded successfully!");
                  }
                })
                .catch((err) => {
                  setIsFileUploading(false);
                  toast.error(
                    err.response.data.message ?? "Something Went Wrong"
                  );
                });
            });
          } //if ends
          else if (submission.changed?.instance?.parent?.type === "datagrid") {
            let ind = submission.changed.instance.path.split(
              `${submission.changed.instance.parent.path}[`
            );
            let ind_ = ind[1].split("]");

            submission?.data[submission.changed.instance.parent.path][
              parseInt(ind_[0])
            ][submission.changed?.component?.key].map((v, i) => {
              let dataToSubmit = {
                activity_id: `${pageContent.job_activity_id}`,
                job_id: `${pageContent.job_id}`,
                file: [v],
              };

              let _axiosInstance = getAxiosInstance(pageContent.token.token);
              _axiosInstance
                .post(uploadFiles, dataToSubmit)
                .then((res) => {
                  data.push({
                    file_id: res.data[i],
                    storage: v.storage,
                    name: v.name,
                    size: v.size,
                    type: v.type,
                    originalName: v.originalName,
                    hash: v.hash,
                  });
                  if (
                    data.length ===
                    submission?.data[submission.changed.instance.parent.path][
                      parseInt(ind_[0])
                    ][submission.changed?.component?.key].length
                  ) {
                    let gridData =
                      submission?.data[submission.changed.instance.parent.path];
                    console.log("gridData", gridData);

                    gridData[parseInt(ind_[0])][
                      submission.changed?.component?.key
                    ] = data;
                    setIsFileUploading(false);
                    console.log("contentData", contentData);
                    if (Object.keys(contentData).length > 0) {
                      Object.keys(contentData).map((v) => {
                        _formContent = updateNestedArray(
                          _formContent,
                          "key",
                          v,
                          contentData[v],
                          "defaultValue"
                        );
                      });
                    }

                    // _formContent = updateNestedArray(
                    //   _formContent,
                    //   "key",
                    //   submission.changed?.component.key,
                    //   data,
                    //   "defaultValue"
                    // );

                    setValue(submission.changed.instance.parent.path, gridData);
                    // setFormContent({
                    //   display: "form",
                    //   components: _formContent,
                    // });

                    toast.success("File uploaded successfully!");
                  }
                })
                .catch((err) => {
                  setIsFileUploading(false);
                  toast.error(
                    err.response.data.message ?? "Something Went Wrong"
                  );
                });
            });
          }
        } else {
          if (
            submission.changed?.component.type === "select" ||
            submission.changed?.component.type === "SelectComponent" ||
            submission.changed?.component.type === "radio" ||
            submission.changed?.component.type === "checkbox" ||
            submission.changed?.component.type === "datetime"
          ) {
            Object.keys(contentData).map((v) => {
              _formContent = updateNestedArray(
                _formContent,
                "key",
                v,
                contentData[v],
                "defaultValue"
              );

              setValue(v, contentData[v]);
            });

            formContent.components = _formContent;

            let _filtered_data1 = findQSLExist(_formContent, "sql");
            let _filtered_data = _filtered_data1.filter((p) => {
              if (p.sql !== undefined && p.sql !== "") {
                let matched_arr = p.sql.match(regex1);
                if (!_.isNull(matched_arr) && matched_arr.length > 0) {
                  if (
                    p.sql.includes(`$$${submission.changed?.component.key}$$`)
                  ) {
                    return p;
                  }
                }
              }
            });
            if (_filtered_data.length > 0) {
              await executeFieldSQLQuery(
                _filtered_data,
                submission.data,
                submission.changed?.component.key,
                submission.data[submission.changed?.component.key]
              );
            } else {
              _formContent = updateNestedArray(
                _formContent,
                "key",
                submission.changed?.component.key,
                submission.data[submission.changed?.component.key],
                "defaultValue"
              );
              setValue(
                submission.changed?.component.key,
                submission.data[submission.changed?.component.key]
              );
              formContent.components = _formContent;
              executeFormJSonChange();
            }
          }
          // if (
          // submission.changed?.component.type === "textarea" ||
          // submission.changed?.component.type === "textfield"
          // )
          else {
            _formContent = updateNestedArray(
              _formContent,
              "key",
              submission.changed?.component.key,
              submission.data[submission.changed?.component.key],
              "defaultValue"
            );
            setValue(
              submission.changed?.component.key,
              submission.data[submission.changed?.component.key]
            );
            formContent.components = _formContent;
          }
        }
      }

      if (submission.changed?.component.type == "button") {
        if (submission.changed?.component.key == "validate") {
          setTimeout(() => {
            _formContent = updateNestedArray(
              _formContent,
              "key",
              "applicantFirstName",
              submission.data["applicantFirstName"],
              "defaultValue"
            );
          }, 1000);

          setTimeout(() => {
            _formContent = updateNestedArray(
              _formContent,
              "key",
              "applicantEmail",
              submission.data["applicantEmail"],
              "defaultValue"
            );
          }, 1400);

          setTimeout(() => {
            _formContent = updateNestedArray(
              _formContent,
              "key",
              "applicantLastName",
              submission.data["applicantLastName"],
              "defaultValue"
            );
          }, 1600);
        } else if (submission.changed?.component.key == "assignSticker") {
          setTimeout(() => {
            _formContent = updateNestedArray(
              _formContent,
              "key",
              "deliveryMessage",
              submission.data["deliveryMessage"],
              "defaultValue"
            );
          }, 1600);
        } else {
        }
        setTimeout(() => {
          setFormContent({
            display: "form",
            components: _formContent,
          });
          setIsDataLoaded(true);
        }, [3000]);
      }
    }
  };

  const setResData = (keys, values) => {
    keys.map((v, i) => {
      setText(v, values[isDataLoaded]);
    });
  };
  let resultV = [];
  const findQSLExist = (arr, key) => {
    let flag = true;
    arr.forEach((_obj) => {
      let obj = { ..._obj };
      if (Object.keys(obj).includes(key)) flag = true;
      if (flag) {
        if (obj?.connection && obj?.sql && obj?.sql !== "") {
          resultV.push(obj);
          flag = false;
        }
      }
      Object.entries(obj).forEach((ele) => {
        if (Array.isArray(ele[1])) {
          findQSLExist(ele[1], key);
        }
      });
    });
    return resultV;
  };
  const onSubmissionBlur = async (submission) => {
    let _formContent = formContent.components;
    setIsChanged(!isChanged);
    const regex1 = /(\$\$.*?\$\$)/gi;
    if (
      submission.changed?.component.type !== undefined &&
      (submission?.component?.type !== "select" ||
        submission?.component?.type !== "SelectComponent" ||
        submission.changed?.component.type !== "radio" ||
        submission.changed?.component.type !== "checkbox")
    ) {
      let _filtered_data = _formContent.filter((p) => {
        if (p.sql !== undefined && p.sql !== "") {
          let matched_arr = p.sql.match(regex1);
          if (!_.isNull(matched_arr) && matched_arr.length > 0) {
            if (p.sql.includes(`$$${submission?.component?.key}$$`)) {
              return p;
            }
          }
        }
      });
      if (_filtered_data.length > 0) {
        await executeFieldSQLQuery(
          _filtered_data,
          submission.data,
          submission?.component?.key,
          submission._data[submission?.component?.key]
        );
      } else {
        let _contentData = contentData;
        _contentData[submission?.component?.key] =
          submission._data[submission?.component?.key];
        setContentData(_contentData);
      }
    }
  };
  const onSelectModelValue = (item, searchKey) => {
    // setShowDialogLoader(true)
    console.log("item after select", item, searchKey);
    let _content = formContent.components;
    let data = _content;
    if (typeof searchKey === "string") {
      data = updateNestedArray(
        data,
        "key",
        searchKey,
        item.value,
        "defaultValue"
      );
    } else {
      searchKey.map((v) => {
        data = updateNestedArray(data, "key", v, item[v], "defaultValue");
      });
    }
    setFormContent({
      display: "form",
      components: data,
    });
    setShowDialogLoader(false);
  };
  const callInmacDialogApi = () => {};
  const setFormDataValueAfterModelOpen = () => {
    let _content = formContent.components;
    let data = _content;
    Object.keys(contentData).map((v) => {
      data = updateNestedArray(data, "key", v, contentData[v], "defaultValue");
    });
    setFormContent({
      display: "form",
      components: data,
    });
  };
  const onSubmitFormData = (data) => {
    let _data = data;
    Object.keys(fileData).map((v, i) => {
      _data.data[v] = fileData[v];
    });

    if (submitCounter === 0) {
      registerWokflow(_data.data);
      setSubmitCounter(submitCounter + 1);
    }
  };

  const registerWokflow = (variables) => {
    let _axiosInstance = getAxiosInstance(pageContent.token.token);
    _axiosInstance
      .post(callRegisterWorkflow, {
        job_id: pageContent.job_id,
      })
      .then((res) => {
        callSetCaseVariableApi(
          res.data.execution_id,
          _axiosInstance,
          variables
        );
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const callSetCaseVariableApi = (execution_id, _axiosInstance, variables) => {
    _axiosInstance
      .post(SetCaseVariable(execution_id), {
        variables,
      })
      .then((res) => {
        setTimeout(() => {
          _axiosInstance
            .get(webentryGetCaseDetails(execution_id))
            .then((res) => {
              console.log("res", res);
              callSubmitCaseApi(
                execution_id,
                _axiosInstance,
                res.data.wf_task_ref_id ?? pageContent.wf_task_ref_id
              );
            })
            .catch((err) => {});
        }, 2000);
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const callSubmitCaseApi = (execution_id, _axiosInstance, wf_task_ref_id) => {
    _axiosInstance
      .post(casesFormActivitySubmit, {
        execution_id,
        activity_id: JSON.stringify(pageContent.job_activity_id),
        wf_task_ref_id,
        isFinalForm: true,
        form_id: pageContent._id,
      })
      .then((res) => {
        setIsFormSubmitted(true);
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  /// AIRA JS FUNCTIONS
  let findValue = (arr, val) => {
    for (let obj of arr) {
      if (obj.key === val) {
        return obj;
      }
      if (obj.components) {
        let result = findValue(obj.components, val);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  };
  const getGridValues = (gridName) => {
    let data = formContent.components;
    let result = findValue(data, gridName);
    if (result?.type === "datagrid") {
      console.log("typeof result", result, typeof result);
      return typeof result === "object" ? result.data : [];
    } else {
      return result?.defaultValue;
    }
  };
  const setGridDataValue = (gridName, data, addNew) => {
    let _formContent = formContent.components;
    _formContent = updateNestedArray(
      _formContent,
      "key",
      gridName,
      data,
      "data"
    );
    setValue(gridName, data);
    var table = document.querySelector(
      `.formio-component-${gridName} table tbody`
    );
    if (table !== null) {
      if (addNew) {
        var rowCount = table?.rows?.length;

        for (var x = rowCount - 1; x > 0; x--) {
          table.deleteRow(x);
        }
      }

      if (addNew && data.length > 0) {
        data.map((v, i) => {
          if (i < data.length) {
            var row = table.insertRow(0);
            Object.keys(v).map((v1, i1) => {
              var cell1 = row?.insertCell(i1);
              if (cell1 !== undefined || cell1 !== null) {
                cell1.innerHTML = v[v1];
              }
            });
          }
        });
      }
      formContent.components = _formContent;
      setFormContent(formContent);
    }
  };
  const updateFieldProperty = (fieldName, fieldValue, propertyName) => {
    let _formContent = formContent.components;
    _formContent = updateAnyFieldInNestedArray(
      _formContent,
      "key",
      fieldName,
      fieldValue,
      propertyName
    );
    if (
      JSON.stringify(formContent.components) !== JSON.stringify(_formContent)
    ) {
      formContent.components = _formContent;
      setIsDataLoaded(!isDataLoaded);
      setFormContent({
        display: "form",
        components: _formContent,
      });
    }
  };
  const setText = (fieldName, fieldValue) => {
    updateFieldProperty(fieldName, fieldValue, "defaultValue");
  };

  const setLabel = (fieldName, fieldValue) => {
    updateFieldProperty(fieldName, fieldValue, "label");
  };
  const disableValidation = (fieldName) => {
    let data = {
      required: false,
    };
    updateFieldProperty(fieldName, data, "validate");
    let ele = getElement(`input[name="data[${fieldName}]"]`);
    ele.removeAttr("required");
  };
  const executeFormJSonChange = () => {
    console.log("called js ");
    eval(formJSON);
  };
  useEffect(() => {
    if (
      !_.isEmpty(formContent.components) &&
      isAllDataLoaded &&
      !_.isEmpty(formJSON)
    ) {
      try {
        const btn = document.querySelector("formio-component-validate");
        console.log("btn", btn);
        btn.addEventListener("click", function () {
          setLabel("applicantLastName", "Applicant Shivani");
        });
        // executeFormJSonChange();
      } catch (e) {
        console.log("===>", e.message !== JSErrorMsg);
        if (JSON.stringify(e.message) !== JSON.stringify(JSErrorMsg)) {
          setJSErrorMsg(e.message);
          setShowJSError(true);
          setTimeout(() => {
            setShowJSError(false);
          }, [5000]);
        } else {
          setShowJSError(false);
        }
      }
    }
  }, [formJSON, isChanged, isAllDataLoaded]);
  return (
    <div>
      {isFormSubmitted ? (
        <>
          <div
            style={{
              width: "100%",
              height: "96vh",
              // background: "red",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src={Images.logo}
                  style={{
                    alignSelf: "center",
                    //width: "100%",
                    width: "120px",
                    // height: "360px",
                  }}
                  alt=""
                />
                <br />
                <Typography
                  component="h3"
                  className="styled-text Bonjour-Julien"
                  style={{
                    fontSize: "22px",
                    fontWeight: "700",
                    color: Colors.airaPrimary,
                  }}
                >
                  Thanks for submitting !
                </Typography>
                <Typography
                  component="span"
                  className="styled-text Bonjour-Julien"
                  style={{ fontSize: "12px", marginTop: "8px" }}
                >
                  Your request has been submitted.
                </Typography>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="main-body" style={{}}>
            <Paper
              elevation={3}
              style={{
                padding: "10px",
              }}
            >
              <div>
                {isFileUploading ? (
                  <p style={{ color: "orange" }}>
                    File uploading in progress...
                  </p>
                ) : null}
                {isDataFetching ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 400,
                    }}
                  >
                    <LogoMonogram className="animated-loader-txt animated-loader-txt-1" />
                  </div>
                ) : (
                  <BuilderUI isDarkMode={isDarkMode}>
                    <form
                      className="myBuilderForm"
                      style={{
                        position: "relative",
                        zIndex: 0,
                      }}
                      onSubmit={(e) => {
                        e.preventDefault();
                        let _data = {
                          data: { ...getValues(), submit: true },
                          state: "submitted",
                        };
                        console.log("_data ", _data);
                        // onSubmitFormData(_data);
                        // setSubmitCounter(submitCounter + 1);
                      }}
                      action="#"
                    >
                      <Form
                        form={formContent}
                        options={{
                          noAlerts: true,
                        }}
                        onSubmit={(data, e) => {
                          console.log("data", data);

                          let updateData = data;
                          updateData.data = {
                            ...updateData.data,
                            ...getValues(),
                          };
                          onSubmitFormData(updateData);
                          // setSubmitCounter(submitCounter + 1);
                        }}
                        onChange={onSubmissionChange}
                        onBlur={onSubmissionBlur}
                        theme={"Darkly"}
                        onCustomEvent={(customEvent) => {
                          setFormDataValueAfterModelOpen();
                          // console.log("customEvent", customEvent);
                          // if (customEvent.type === "onClickVerifyQR") {
                          //   onClickVerifyQR(true);
                          // } else
                          if (customEvent.type === "onClickCostCenter") {
                            setOpenCostCenterDialog(true);
                          } else if (customEvent.type === "onClickBuyerGroup") {
                            setOpenBuyerGoupDialog(true);
                          } else if (
                            customEvent.type === "onClickPuchaseOrganization"
                          ) {
                            setopenPuchaseOrgDialog(true);
                          } else if (customEvent.type === "onClickCenter") {
                            setCenterDialog(true);
                          } else if (customEvent.type === "onClickAddress") {
                            setAddressDialog(true);
                          } else if (
                            customEvent.type === "onClickInternalOrder"
                          ) {
                            setInternalOrderDialog(true);
                          } else if (
                            customEvent.type === "onClickCatalogInmac"
                          ) {
                            callInmacDialogApi();
                          } else if (customEvent.type === "onClickProviders") {
                            setProvidersDialog(true);
                          }
                          if (customEvent.type === "onClickCancel") {
                            navigate(-1);
                          }
                        }}
                      />
                    </form>
                  </BuilderUI>
                )}
              </div>
            </Paper>
            {providersDialog && (
              <ProviderDialog
                onClose={() => setProvidersDialog(false)}
                open={providersDialog}
                onSelectItem={(item) =>
                  onSelectModelValue(item, [
                    "idPaymentItem",
                    "textVarID007",
                    "incotermsLabel",
                    "nomDonnfourni",
                    "textVarID009",
                    "textVarText008",
                    "incoterms2ndPartie",
                    "inmac_store_name",
                    "inmac_add1",
                    "inmac_add2",
                    "s_country",
                    "s_tel",
                    "s_fax",
                  ])
                }
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
            {openCostCenterDialog && (
              <CostCenterDialog
                onClose={() => setOpenCostCenterDialog(false)}
                open={openCostCenterDialog}
                onSelectItem={(item) => onSelectModelValue(item, "costCenter")}
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
            {openBuyerGoupDialog && (
              <BuyerGroupDialog
                onClose={() => setOpenBuyerGoupDialog(false)}
                open={openBuyerGoupDialog}
                onSelectItem={(item) => onSelectModelValue(item, "buyerGroup")}
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
            {openPuchaseOrgDialog && (
              <PuchaseOrganizationDialog
                onClose={() => setopenPuchaseOrgDialog(false)}
                open={openPuchaseOrgDialog}
                onSelectItem={(item) =>
                  onSelectModelValue(item, "purchaseOrganization")
                }
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
            {internalOrderDialog && (
              <InternalOrderDialog
                onClose={() => setInternalOrderDialog(false)}
                open={internalOrderDialog}
                onSelectItem={(item) =>
                  onSelectModelValue(item, "internalOrder")
                }
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
            {centerDialog && (
              <CenterDialog
                onClose={() => setCenterDialog(false)}
                open={centerDialog}
                onSelectItem={(item) =>
                  onSelectModelValue(item, [
                    "centerCode",
                    "nomMag",
                    "streetAndN1",
                    "postalCode1",
                    "city1",
                  ])
                }
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
            {addressDialog && (
              <AddressDialog
                onClose={() => setAddressDialog(false)}
                open={addressDialog}
                onSelectItem={(item) =>
                  onSelectModelValue(item, "interlocuteur")
                }
                //addressOther
                setShowDialogLoader={(val) => setShowDialogLoader(val)}
                showDialogLoader={showDialogLoader}
                isWebEntry={true}
                pageContent={pageContent}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default FormRendering;
