// routes.tsx

import { IRoutesConfig } from "auth-react-router";
import React from "react";

import Loader from "../components/Loader/Loader";
import ScreenLoader from "../components/Loader/LoaderScreen";
import SignOnLoading from "../views/SignOnLoaging/SignOnLoaging";
import FormRendering from "../views/WebLinkForm/FormRendering";
import AzureConnectionLoader from "../views/connections/connectionDialogs/azure/AzureConnectionLoader";
import GConnectionLoader from "../views/connections/connectionDialogs/google/GConnectionLoader";
import MailChimpConnectionLoader from "../views/connections/connectionDialogs/mailchimp/MailChimpConnectionLoader";
import SalesForceConnectionLoader from "../views/connections/connectionDialogs/salesforce/SalesForceConnectionLoader";
import LandingPage from "../views/landingPage/LandingPage";
import ResetPasswordPage from "../views/resetPassword/ResetPasswordPage";
import ImportLicense from "../views/ImportLicense/ImportLicense";
//No page found component
const NoPageFound = React.lazy(
  () => import("../components/NoPageFound/NoPageFound")
);

// public lazy loaded pages
// const LandingPage = React.lazy(
//   () => import("../views/LandingPage/LandingPage")
// );

// private lazy loaded pages
const DefaultLayout = React.lazy(() => import("../views/layout/DefaultLayout"));

export const routes: IRoutesConfig = {
  publicRedirectRoute: "/login", // redirect to `/profile` when authorized is trying to access public routes
  privateRedirectRoute: "/app", // redirect to `/login` when unauthorized user access a private route
  defaultFallback: <Loader loading={true} />,
  InvalidUserRoleFallback: ({ currentUserRole, routeRequiredRoles }) => <p></p>,
  public: [
    {
      path: "/",
      component: <LandingPage />,
    },
    {
      path: "/login",
      component: <LandingPage />,
    },
    {
      path: "/resetpassword",
      component: <ResetPasswordPage />,
    },
    {
      path: "/signon",
      component: <SignOnLoading />,
    },
    {
      path: "/importlicense",
      component: <ImportLicense />,
    },
  ],
  private: [
    {
      path: "/app/*",
      component: <DefaultLayout />,
    },
    {
      path: "/app/esLoader",
      component: <Loader loading={true} />,
    },
    {
      path: "*",
      component: <NoPageFound />,
    },
  ],
  common: [
    {
      path: "/gconnectionLoader",
      component: <GConnectionLoader />,
    },
    {
      path: "/azureconnectionLoader",
      component: <AzureConnectionLoader />,
    },
    {
      path: "/salesForceconnectionLoader",
      component: <SalesForceConnectionLoader />,
    },
    {
      path: "/mailchimpconnectionLoader",
      component: <MailChimpConnectionLoader />,
    },
    {
      path: "*",
      component: <NoPageFound />,
    },
    {
      path: "/app/loading",
      component: (
        <ScreenLoader loading={true} showMsg={false} msg={""} styleProp={{}} />
      ),
    },
    {
      path: "/web/webentry",
      component: <FormRendering />,
    },
  ],
};
