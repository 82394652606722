export const formIoData = {
  display: "form",
  components: [],
};
let additionalComponent = [
  {
    type: "select",
    input: true,
    key: "referenceVariable",
    label: "Add Reference Variable",
    tooltip:
      "If you want a value from a different variable, choose that variable.",
    dataSrc: "values",
    weight: 1,
    placeholder: "Add Variable",
    data: {
      values: [],
    },
  },
  {
    type: "select",
    input: true,
    key: "connection",
    label: "Add Connection",
    tooltip: "If you want to execute query, Please select a connection",
    dataSrc: "values",
    weight: 1,
    placeholder: "Add Connection",
    data: {
      values: [],
    },
  },
  {
    weight: 1,
    type: "SQLField",
    input: true,
    key: "sql",
    label: "Add SQL",
    placeholder: "Add SQL",
    components: {},
    validation: {
      required: true,
    },
  },
  {
    key: "tags",
    ignore: true,
  },
  {
    key: "properties",
    ignore: true,
  },
];
let excludeDisplay = [
  {
    key: "prefix",
    ignore: true,
  },
  {
    key: "suffix",
    ignore: true,
  },
  {
    key: "widget",
    ignore: true,
  },
  {
    key: "inputMask",
    ignore: true,
  },
  {
    key: "displayMask",
    ignore: true,
  },
  {
    key: "allowMultipleMasks",
    ignore: true,
  },
  {
    key: "hideLabel",
    ignore: true,
  },
];
let excludeData = [
  {
    key: "persistent",
    ignore: true,
  },
  {
    key: "inputFormat",
    ignore: true,
  },
  {
    key: "protected",
    ignore: true,
  },
  {
    key: "dbIndex",
    ignore: true,
  },
  {
    key: "calculateServer",
    ignore: true,
  },
  {
    key: "truncateMultipleSpaces",
    ignore: true,
  },
  {
    key: "encrypted",
    ignore: true,
  },
  {
    key: "case",
    ignore: true,
  },
  {
    key: "customDefaultValue",
    ignore: true,
  },
  {
    key: "calculateValue",
    ignore: true,
  },
  {
    key: "redrawOn",
    ignore: true,
  },
  {
    key: "clearOnHide",
    ignore: true,
  },
];
let editSettings = [
  {
    key: "api",
    title: "Properties",
    label: "Properties",
    components: additionalComponent,
  },
  {
    key: "display",
    components: excludeDisplay,
  },
  {
    key: "data",
    components: excludeData,
  },
  {
    key: "layout",
    ignore: true,
  },
  {
    key: "logic",
    ignore: true,
  },
  {
    key: "addons",
    ignore: true,
  },
];
export const formioOptions = {
  noDefaultSubmitButton: true,
  disableAlerts: true,
  noAlerts: true,
  builder: {
    basic: {
      title: "Basic Inputs",
      components: {
        file: true,
        datagrid: true,
        nestedform: true,
        nestedForm: true,
        form: true,
        hidden: false,
        datetime: true,
        dateTime: true,
      },
    },
    advanced: {
      title: "Advance Inputs",
      components: {
        address: false,
        tags: false,
        survey: false,
      },
    },
    layout: {
      title: "Layout Components",
      components: {
        fieldset: false,
        well: false,
        content: false,
      },
    },
    premium: false,
    data: false,
    custom: {
      title: "Custom",
      components: {
        SelectComponent: true,
        DatePickerComponent: true,
        GeoLocationComponent: true,
        QRScannerComponent: true,
      },
    },
  },
  editForm: {
    textfield: editSettings,
    textarea: editSettings,
    number: editSettings,
    password: editSettings,
    checkbox: editSettings,
    radio: editSettings,
    email: editSettings,
    selectbox: editSettings,
    url: editSettings,
    phonenumber: editSettings,
    tags: editSettings,
    address: editSettings,
    datetime: editSettings,
    dateTime: editSettings,
    day: editSettings,
    time: editSettings,
    currency: editSettings,
    survey: editSettings,
    signature: editSettings,
    select: editSettings,
    SelectComponent: [
      {
        key: "api",
        title: "Properties",
        label: "Properties",
        components: additionalComponent,
      },
      {
        key: "display",
        components: excludeDisplay,
      },
      {
        key: "data",
        components: [
          {
            datasrc: true,
            datatype: true,
          },
        ],
      },
      {
        key: "layout",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
    ],
    htmlelement: [
      {
        key: "api",
        title: "Properties",
        label: "Properties",
        components: additionalComponent,
      },
      {
        key: "display",
        components: [
          {
            key: "hideLabel",
            ignore: true,
          },
        ],
      },
    ],
    form: [
      {
        key: "form",
        components: [
          {
            type: "select",
            input: true,
            dataSrc: "url",
            data: {
              url: "api/v1/form?limit=100&skip=0",
            },
            searchField: "title__regex",
            template: "<span>{{ item.title }}</span>",
            valueProperty: "_id",
            authenticate: true,
            label: "Form",
            key: "form",
            weight: 10,
            lazyLoad: false,
            tooltip: "The form to load within this form component.",
            validate: {
              required: true,
            },
          },
        ],
      },
    ],
  },
};
